<template>
    <div>
        <CDataTable
            :fields="fields"
            :items="checkList"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            :pagination="true"
            v-on:refresh="orderCheckList()"
            class="modalTable"
        >
        </CDataTable>
    </div>
</template>

<script>

    export default{
        name: "OrderCheckList",
        props:{
            params: Object,
        },
        computed:{
            loading: function() {
                return this.$store.getters.productionLoading
            },
            checkList: function() {
                return this.$store.getters.productionOrderCheckList
            }
        },
        watch:{
            params: function(val){
                this.data = {...val};
                this.orderCheckList()
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                fields: [
                    {key: 'id', label: "ID"},
                    {key: 'productionName', label: 'Üretici Adı', _classes: 'font-weight-bold'},
                    {key: 'productName', label: 'Ürün', _classes: 'font-weight-bold'},
                    {key: 'orderAmount', label: 'Sipariş Adeti'},
                    {key: 'packingListAmount', label: 'Çeki Listesi Adeti'},
                    {key: 'invoiceAmount', label: 'Fatura Adeti'},

                ],
            }
        },
        mounted: function(){
            this.orderCheckList()
        },
        methods: {
            orderCheckList: async function(){
                await this.$store.dispatch('productionOrderCheck_list', {productionOrderId: this.data.id})
            },
        }
    }
</script>